import { createSchema } from 'entities/Seo/lib';
import type { ICreateSchemaOptions, ICreateSchemaParams } from 'entities/Seo/types';

type SeoSchemaProps = ICreateSchemaParams & ICreateSchemaOptions;

export const SeoSchema = ({
  seo,
  microcredits,
  currentLocation,
  metadata,
  path,
  microcredit,
  ...includes
}: SeoSchemaProps) => {
  const schema = createSchema({ seo, microcredit, microcredits, currentLocation, metadata, path }, includes);
  if (!schema) {
    return null;
  }
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: schema,
      }}
    />
  );
};
