import type { ILocation } from '@src/@types/locations';
import { loadIndexingRegions } from '@src/services/metadata';

export const getFormatPath = (asPath: string, regionAlias: string) => async (location: ILocation) => {
  const url = new URL(asPath, 'https://sravni.ru/');

  if (url.pathname.match(regionAlias)) {
    url.pathname = url.pathname.replace(`${regionAlias}/`, '');
  }

  const indexedRegions = await loadIndexingRegions(url.pathname);

  const isIndexed = indexedRegions.includes(location.id);

  if (isIndexed) {
    return `${url.pathname}${location.alias}/${url.search}`;
  }

  return `${url.pathname}${url.search}`;
};
