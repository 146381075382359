import scrollToElement from 'scroll-to-element';

import { format } from '@sravni/utils/lib/money';

import type { Currency } from '@src/@types/currency';
import type * as Ranges from '@src/@types/ranges';

import { getSymbolByCode } from './currency';
import type { IHumanizeOptions } from './duration';
import { getFullUnitCount, humanize as humanizeDurationTerm } from './duration';

export const humanizeTerm = humanizeDurationTerm;

type MakeOptional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export const humanizeTermRange = (
  term: MakeOptional<Ranges.ITermRange, 'from' | 'to'>,
  options?: IHumanizeOptions,
): string => {
  const { from, fromUnit, to, toUnit } = term || {};

  if (from && to) {
    if (from === to) {
      return humanizeTerm(to, toUnit, options);
    }

    if (fromUnit === toUnit) {
      const left = getFullUnitCount(from, fromUnit);
      const right = humanizeTerm(to, toUnit, options);

      return options?.useAlternativeFormat ? `от ${left} до ${right}` : `${left} – ${right}`;
    }

    const humanizedFrom = humanizeTerm(from, fromUnit, options);
    const humanizedTo = humanizeTerm(to, toUnit, options);

    return options?.useAlternativeFormat
      ? `от ${humanizedFrom} до ${humanizedTo}`
      : `${humanizedFrom} – ${humanizedTo}`;
  }
  if (from) {
    return `от ${humanizeTerm(from, fromUnit, options)}`;
  }
  if (to) {
    return `до ${humanizeTerm(to, toUnit, options)}`;
  }

  return 'Любой';
};

export const considerationTimeRange = (term: Ranges.ITermRange, noneValueText: string): string => {
  const { from, fromUnit, to, toUnit } = term;

  if (from && to) {
    if (from === to) {
      return humanizeTerm(to, toUnit);
    }

    if (fromUnit === toUnit) {
      const left = getFullUnitCount(from, fromUnit);
      const right = humanizeTerm(to, toUnit);

      return `${left} – ${right}`;
    }

    return `${humanizeTerm(from, fromUnit, {
      initialForm: true,
    })} – ${humanizeTerm(to, toUnit, {
      initialForm: true,
    })}`;
  }
  if (from) {
    return `от ${humanizeTerm(from, fromUnit, {
      initialForm: false,
    })}`;
  }
  if (to) {
    return `до ${humanizeTerm(to, toUnit, {
      initialForm: false,
    })}`;
  }

  return noneValueText ?? 'Любой';
};

export const getAmountString = (amount: string | number | undefined, currency?: Currency): string => {
  if (typeof amount === 'undefined') {
    return '';
  }
  const symbol = currency ? ` ${getSymbolByCode(currency)}` : '';

  return `${format(amount)}${symbol}`;
};

interface IAmountRangeToString {
  amountRange: Ranges.IFromToRange;
  currency?: Currency;
  prefix?: boolean;
  defaultText?: string;
}

export const getAmountRangeToString = (params: IAmountRangeToString): string => {
  const { amountRange, currency, prefix = false, defaultText = 'Любая' } = params;
  const { from, to } = amountRange || {};
  let output = '';

  if (!from && !to) return defaultText;

  if (from && to && from !== to) {
    output = `${getAmountString(from, currency)} – ${getAmountString(to, currency)}`;
  } else if (from || to) {
    output = `${getAmountString(from || to, currency)}`;
  }

  if (prefix) {
    if (from && !to) {
      output = `от ${output}`;
    } else if (to && !from) {
      output = `до ${output}`;
    }
  }

  return output;
};

const getRateString = (rate: number, withPercent = true): string =>
  `${parseFloat(rate.toFixed(2))}${withPercent ? '%' : ''}`.replace('.', ',');

export const getRateRangeString = (
  rateRange: Ranges.IFromToRange,
  prefix = false,
  withPercent = true,
  withSymbol = false,
) => {
  const { from, to } = rateRange || {};
  let output = '';

  if (from !== undefined && to !== undefined && from !== to) {
    if (withSymbol) {
      output = `${getRateString(from, withPercent)} до ${getRateString(to, withPercent)}`;
    } else {
      output = `${getRateString(from, withPercent)} – ${getRateString(to, withPercent)}`;
    }
  } else if (from !== undefined || to !== undefined) {
    output = getRateString(from !== undefined ? from : to, withPercent);
  }

  if (prefix) {
    if (from < to) {
      output = `от ${output}`;
    } else if (from && !to) {
      output = `до ${output}`;
    }
  }

  return output;
};

export const scrollToElementById = (id: string, options = { offset: 0 }) => {
  const element = document.getElementById(id);
  if (element) {
    scrollToElement(element, options);
  }
};

export const timeRange = (term: Ranges.ITermRange) => {
  const { from, fromUnit, to, toUnit } = term;

  if (from && to) {
    if (from === to) {
      return humanizeTerm(to, toUnit);
    }

    if (fromUnit === toUnit) {
      const left = getFullUnitCount(from, fromUnit);
      const right = humanizeTerm(to, toUnit, { termWithUnit: true, isRange: true });

      return `от ${left} до ${right}`;
    }

    return `от ${humanizeTerm(from, fromUnit, {
      initialForm: true,
      isRange: true,
      termWithUnit: true,
    })} до ${humanizeTerm(to, toUnit, {
      initialForm: true,
      isRange: true,
      termWithUnit: true,
    })}`;
  }
  if (from) {
    return `от ${humanizeTerm(from, fromUnit, {
      termWithUnit: true,
      isRange: true,
    })}`;
  }
  if (to) {
    return `до ${humanizeTerm(to, toUnit, {
      termWithUnit: true,
      isRange: true,
    })}`;
  }

  return '';
};
