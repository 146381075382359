export const GTM_ID = {
  DEFAULT: 'GTM-PMDFG9',
  WL: 'GTM-58R4BJK',
};

export const PIXELS_TYPES = {
  CLICK: 'click',
  DISPLAY: 'display',
  TRACKING: 'tracking',
};
