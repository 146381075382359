import { makeMainGEvent, pushEvent } from '@src/utils/analytics';

export const sendOctoberPrizeDrawGoToVkVideoEvent = () => {
  pushEvent(
    makeMainGEvent({
      eventCategory: 'Микрокредиты',
      eventAction: 'Розыгрыш|Переход на страницу результатов',
      eventLabel: 'Акция OZON октябрь 2024',
    }),
  );
};

export const sendOctoberPrizeDrawShowAllHistoriesEvent = () => {
  pushEvent(
    makeMainGEvent({
      eventCategory: 'Микрокредиты',
      eventAction: 'Розыгрыш|Смотреть истории',
      eventLabel: 'Акция OZON октябрь 2024',
    }),
  );
};

export const sendCheckPhoneNumberClickEvent = () => {
  pushEvent(
    makeMainGEvent({
      eventCategory: 'Микрокредиты',
      eventAction: 'Розыгрыш|Проверить результаты',
      eventLabel: 'Акция OZON октябрь 2024',
      eventValue: undefined,
    }),
  );
};
