import { useRouter } from 'next/router';
import type { FC } from 'react';
import { useSelector } from 'react-redux';

import { checkAndSendPageViewGAEvent } from '@src/helpers/analyticsEvents';
import { useCurrentLocation } from '@src/reducers/locations';
import { getCreditsLists, getCreditsMetadata } from '@src/reducers/microcredits';
import { selectItemSource } from '@src/reducers/product';
import { useSeoState } from '@src/reducers/seo';
import { isServer } from '@src/utils';
import { getCommonReplacements } from '@src/utils/meta';
import { SeoMeta } from 'entities/Seo';
import { BASE_URL, VITRINA_SEO_KEYS } from 'entities/Seo/config';
import { getTitle, getDescription } from 'entities/Seo/lib';

import { SeoSchema } from './SeoSchema';

export const SeoHead: FC = () => {
  const { asPath } = useRouter();
  const { pathname, seo } = useSeoState();
  const metadata = useSelector(getCreditsMetadata);
  const currentLocation = useCurrentLocation();
  const cardSource = useSelector(selectItemSource);
  const lists = useSelector(getCreditsLists);
  const microcredits = lists[VITRINA_SEO_KEYS.find((virtinaName) => lists[virtinaName]?.items.length)];

  if (pathname?.includes('mfo') && cardSource) {
    return (
      <SeoMeta
        indexingDisabled={seo?.indexingDisabled}
        title={getTitle(cardSource)}
        description={getDescription(cardSource)}
        canonical={`${BASE_URL}${pathname}`}
      >
        <SeoSchema seo={seo} path={asPath} microcredit={cardSource} offer faq page />
      </SeoMeta>
    );
  }

  if (!seo?.title || !seo?.description) {
    return null;
  }

  if (!isServer) {
    checkAndSendPageViewGAEvent(seo.title, pathname);
  }

  return (
    <SeoMeta
      indexingDisabled={seo.indexingDisabled}
      title={getCommonReplacements({ template: seo.title, meta: metadata, location: currentLocation })}
      description={getCommonReplacements({
        template: seo.description,
        meta: metadata,
        location: currentLocation,
      })}
      canonical={seo.canonical || `${BASE_URL}${pathname}`}
      ogDescription={seo.ogDescription}
      ogTitle={seo.ogTitle}
    >
      <SeoSchema
        seo={seo}
        microcredits={microcredits}
        path={asPath}
        currentLocation={currentLocation}
        metadata={metadata}
        faq
        product
        page
      />
    </SeoMeta>
  );
};
