import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { sendVisitVitrinEvent } from '@src/helpers/mindbox/mindboxEvents';
import { isProduction } from '@src/utils';
import { checkIsNeededToSendVisitMindboxEvent } from '@src/utils/checkForSendMindboxVisitEvent';
import queue from '@src/utils/queue';

const WEBPUSH_DELAY = 7000;

export const useInitSentryAndMindbox = () => {
  const { asPath } = useRouter();
  const [pathWithoutQuery] = asPath.split('?');

  const isNeededToSendVisitMindboxEvent = checkIsNeededToSendVisitMindboxEvent(pathWithoutQuery);

  useEffect(() => {
    if (!isProduction) {
      return;
    }

    const { publicRuntimeConfig } = getConfig();

    queue.push(() => {
      import('@sentry/browser').then((Sentry) => {
        Sentry.init({
          dsn: publicRuntimeConfig.sentryDSN,
          release: publicRuntimeConfig.release,
          environment: publicRuntimeConfig.environment,
        });
      });
    });

    queue.push(() => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker
          .register('/service-worker.js')
          .then(() => {
            setTimeout(() => {
              if (typeof window.mindbox === 'undefined') {
                return;
              }

              window.mindbox('webpush.subscribe', {
                onGranted: () => undefined,
                onDenied: () => undefined,
              });
            }, WEBPUSH_DELAY);
            if (isNeededToSendVisitMindboxEvent) {
              sendVisitVitrinEvent(pathWithoutQuery);
            }
          })
          .catch((error) => {
            console.error('Error during service worker registration:', error);
          });
      }
    });
  }, [isNeededToSendVisitMindboxEvent, pathWithoutQuery]);
};
