import type { IClientMicrocreditListItem } from '@src/@types/clienttypes';
import { getCurrency } from '@src/helpers/currency';
import type { SchemaJsonLD } from 'entities/Seo/types';

import { BASE_URL } from '../../config';

import { getAddress } from './getAdress';

export const mapMicrocreditToSchema =
  (pageName: string, path: string) =>
  (product: IClientMicrocreditListItem, index: number): SchemaJsonLD.ListItem => {
    const { rates } = product;

    const rangeData = {
      amount: { minValue: Infinity, maxValue: -Infinity },
      rate: { minValue: Infinity, maxValue: -Infinity },
      term: { minValue: Infinity, maxValue: -Infinity },
    };
    rates.forEach((rate) => {
      rangeData.amount.minValue = Math.min(rate.amount.from, rangeData.amount.minValue);
      rangeData.amount.maxValue = Math.max(rate.amount.to, rangeData.amount.maxValue);
      rangeData.rate.minValue = Math.min(rate.rate.from, rangeData.rate.minValue);
      rangeData.rate.maxValue = Math.max(rate.rate.to, rangeData.rate.maxValue);
      rangeData.term.minValue = Math.min(rate.term.fromValue / 60 / 24, rangeData.term.minValue);
      rangeData.term.maxValue = Math.max(rate.term.toValue / 60 / 24, rangeData.term.maxValue);
    });
    return {
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@type': 'LoanOrCredit',
        name: `Займ «${product.name}»`,
        description: `Займ ${product.name} от МФО «${product.organization.name}» с суммой от ${rangeData.amount.minValue} до ${rangeData.amount.maxValue} рублей на срок от ${rangeData.term.minValue} до ${rangeData.term.maxValue} дней.`,
        url: `${BASE_URL}/zaimy/mfo/${product.organization.alias}/${product.alias}`,
        image: product.organization.logotypes.android || product.organization.logo,
        category: 'Займ',
        feesAndCommissionsSpecification: `${BASE_URL}/zaimy/mfo/${product.organization.alias}/${product.alias}`,
        amount: {
          '@type': 'MonetaryAmount',
          currency: getCurrency(path),
          ...rangeData.amount,
        },
        loanTerm: {
          '@type': 'QuantitativeValue',
          name: 'Срок займа',
          ...rangeData.term,
          unitCode: 'DAY',
        },
        annualPercentageRate: {
          '@type': 'QuantitativeValue',
          name: 'Процентная ставка в день',
          ...rangeData.rate,
          unitText: '%',
        },
        broker: {
          '@type': 'BankOrCreditUnion',
          name: product.organization.name,
          priceRange: '$$$$',
          image: [product.organization.logotypes.android || product.organization.logo],
          url: `https://www.sravni.ru/mfo/${product.organization.alias}/`,
          logo: product.organization.logotypes.android || product.organization.logo,
          telephone: product.organization.contacts.phone,
          address: {
            '@type': 'PostalAddress',
            ...getAddress(product.organization.contacts.address, 'ru'),
          },
        },
        potentialAction: {
          '@type': 'ApplyAction',
          name: `Оформить займ`,
          url: product.advertising.monetization.pixels.click,
        },
      },
    };
  };
