import type { ICreateSchemaParams, ICreateSchemaOptions } from 'entities/Seo/types';

import { mapCardToSchema } from './mapCardToSchema';
import { mapFAQtoSchema } from './mapFAQtoSchema';
import { mapMicrocreditsToProduct } from './mapMicrocreditsToProduct';
import { mapPageToSchema } from './mapPageToSchema';

export function createSchema(params: ICreateSchemaParams, { faq, page, product, offer }: ICreateSchemaOptions): string {
  try {
    if (!params?.microcredits?.items?.length && product) {
      return JSON.stringify(params.seo.schema);
    }

    return JSON.stringify(
      [
        offer && mapCardToSchema(params),
        product && mapMicrocreditsToProduct(params),
        faq && mapFAQtoSchema(params),
        page && mapPageToSchema(params),
      ].filter((x) => !!x),
    );
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('SeoSchema error parsing');

    return JSON.stringify([]);
  }
}
