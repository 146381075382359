import Head from 'next/head';
import type { ReactNode } from 'react';

interface ISeoMetaProps {
  title: string;
  description: string;
  canonical: string;
  children?: ReactNode;
  indexingDisabled?: boolean;
  ogTitle?: string;
  ogDescription?: string;
}

export const SeoMeta = ({
  title,
  description,
  canonical,
  indexingDisabled,
  ogTitle,
  ogDescription,
  children,
}: ISeoMetaProps) => (
  <Head>
    <title>{title}</title>
    <meta name="description" content={description} />
    <link rel="canonical" href={canonical} />
    <meta name="robots" content={indexingDisabled ? 'noindex, nofollow' : 'index, follow'} />
    {ogDescription && <meta property="og:description" content={ogDescription} />}
    {ogTitle && <meta property="og:title" content={ogTitle} />}
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://f.sravni.ru/f/_/logo_200x200.jpg" />
    <meta property="og:image:width" content="200" />
    <meta property="og:image:height" content="200" />
    {children && children}
  </Head>
);
