import { useRouter } from 'next/router';
import type { FC } from 'react';
import React, { memo } from 'react';

import { CommonHead } from '@sravni/next-common-head';
import { useTheme } from '@sravni/react-utils';

import { useSelectUser } from '@src/reducers/user';

import { PRELOAD_FONTS, getGtmOptions } from './lib';

interface Props {
  isWhiteLabel?: boolean;
}

export const CustomCommonHead: FC<Props> = memo(({ isWhiteLabel }) => {
  const { asPath } = useRouter();

  const user = useSelectUser();
  const [theme] = useTheme();

  const analyticsOptions = getGtmOptions({
    userId: user?.sub,
    isWhiteLabel,
    asPath,
    theme,
  });

  return (
    <CommonHead
      analyticsOptions={analyticsOptions}
      // @ts-ignore
      designSystem="custom" // хак, чтобы не подтягивались все дефолтные шрифты, зашитые в CommonHead
      preloadedFonts={PRELOAD_FONTS}
    />
  );
});

CustomCommonHead.displayName = 'CustomCommonHead';
