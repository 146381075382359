import type { ICommonHeadProps } from '@sravni/next-common-head';

const CDN = 'https://s91588.cdn.ngenix.net';

export const PRELOAD_FONTS: ICommonHeadProps['preloadedFonts'] = [
  {
    url: `${CDN}/shared/static/fonts/aeroport/Aeroport-bold.woff2`,
    type: `font/woff2`,
  },
  {
    url: `${CDN}/shared/static/fonts/aeroport/Aeroport-bold.woff`,
    type: `font/woff`,
  },
];
