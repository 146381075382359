import { DEFAULT_CITY, DEFAULT_POSTAL_CODE } from 'entities/Seo/config';
import type { SchemaJsonLD } from 'entities/Seo/types';

export const getAddress = (addressString: string, region: 'ru' | 'kz'): Partial<SchemaJsonLD.PostalAddress> => {
  if (!addressString) {
    return {
      addressLocality: DEFAULT_CITY,
      postalCode: DEFAULT_POSTAL_CODE,
      addressCountry: region === 'kz' ? 'KZ' : 'RU',
    };
  }
  const [postalCode, city, ...street] = addressString.split(',');
  return {
    streetAddress: street.join(',').trim(),
    addressLocality: city?.trim() || DEFAULT_CITY,
    postalCode: parseInt(postalCode, 10) || DEFAULT_POSTAL_CODE,
    addressCountry: region === 'kz' ? 'KZ' : 'RU',
  };
};
