import { get } from 'lodash';

export const getRange = (value: unknown[], [pathFrom, pathTo]: string[]): { maxValue: number; minValue: number } => {
  if (!value.length) {
    return { maxValue: 1, minValue: 0 };
  }
  const result = { maxValue: -Infinity, minValue: Infinity };
  value.forEach((item) => {
    const from = Number(get(item, pathFrom));
    const to = Number(get(item, pathTo));

    result.minValue = Number.isNaN(from) ? result.minValue : Math.min(result.minValue, from);
    result.maxValue = Number.isNaN(to) ? result.maxValue : Math.max(result.maxValue, to);
  });
  if (result.maxValue === -Infinity) result.maxValue = 1;
  if (result.minValue === Infinity) result.minValue = 0;
  return result;
};
