import { ListKeys } from '@src/constants/microcredits';

export const MAX_RATING_VALUE = 5;
export const MIN_RATING_VALUE = 4.7;

export const DEFAULT_CITY = 'Москва';
export const DEFAULT_POSTAL_CODE = '101000';

export const MAX_REVIEW_COUNT_VALUE = 10000;
export const MIN_REVIEW_COUNT_VALUE = 1000;

export const BEST_RATING_VALUE = '5';

export const WORST_RATING_VALUE = '1';

export const MIN_AMOUNT = '100';
export const BASE_URL = 'https://www.sravni.ru';

export const VITRINA_SEO_KEYS = [
  ListKeys.LIST,
  ListKeys.ONLINE,
  ListKeys.NOVINKI,
  ListKeys.CALCULATOR,
  ListKeys.LANDING,
  ListKeys.BRANDING,
  ListKeys.LEADSSU,
  ListKeys.GURULEADS,
  ListKeys.ZAIMY_POD_PTS,
  ListKeys.KAZAKHSTAN,
];
