import * as duration from 'duration-fns';

import { TERMS } from '@src/@types/ranges';

import { plur } from './plur';

const UNIT_TO_METHOD = {
  [TERMS.years]: duration.toYears,
  [TERMS.months]: duration.toMonths,
  [TERMS.days]: duration.toDays,
  [TERMS.minutes]: duration.toMinutes,
  [TERMS.hours]: duration.toHours,
  [TERMS.seconds]: duration.toSeconds,
};

export const getFullUnitCount = (ISODuration: string, unit: TERMS) => {
  if (!unit) {
    return undefined;
  }
  const method = UNIT_TO_METHOD[unit];
  return method(ISODuration);
};

export interface IHumanizeOptions {
  initialForm?: boolean;
  termWithUnit?: boolean;
  useAlternativeFormat?: boolean;
  isRange?: boolean;
}

export const humanize = (ISODuration: string, unit: TERMS, options?: IHumanizeOptions) => {
  const value = getFullUnitCount(ISODuration, unit);
  const { initialForm, termWithUnit = true, isRange = false } = options || {};

  if (!termWithUnit) {
    return `${value}`;
  }

  const unitString = plur({
    value,
    unit,
    initialForm,
    isRange,
  });

  return `${value} ${unitString}`;
};
