import { chain } from 'lodash';
import random from 'lodash/random';

import { getCurrency } from '@src/helpers/currency';
import { getCommonReplacements } from '@src/utils/meta';
import { MIN_REVIEW_COUNT_VALUE, MAX_REVIEW_COUNT_VALUE } from 'entities/Seo/config';
import type { ICreateSchemaParams, SchemaJsonLD } from 'entities/Seo/types';

import { mapMicrocreditToSchema } from './mapMicrocreditToSchema';

export const mapMicrocreditsToProduct = ({
  seo,
  microcredits,
  metadata,
  currentLocation,
  path,
}: ICreateSchemaParams): SchemaJsonLD.Product => ({
  '@context': 'http://schema.org',
  '@type': 'Product',
  name: getCommonReplacements({ template: seo.title, meta: metadata, location: currentLocation }),
  description: getCommonReplacements({
    template: seo.description,
    meta: metadata,
    location: currentLocation,
  }),
  aggregateRating: {
    '@type': 'AggregateRating',
    ratingValue: '4,8',
    bestRating: '5',
    worstRating: '1',
    reviewCount: random(MIN_REVIEW_COUNT_VALUE, MAX_REVIEW_COUNT_VALUE).toString(),
  },
  offers: {
    '@type': 'AggregateOffer',
    lowPrice: metadata.minAmount.amount,
    highPrice: metadata.maxAmount.amount,
    priceCurrency: getCurrency(path).toString(),
    offerCount: microcredits.total,
    offers: {
      '@type': 'ItemList',
      numberOfItems: microcredits.total,
      itemListElement: chain(microcredits.items).map(mapMicrocreditToSchema(seo.productName, path)).value(),
    },
  },
});
