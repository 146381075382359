import { BASE_URL } from 'entities/Seo/config';
import type { ICreateSchemaParams, SchemaJsonLD } from 'entities/Seo/types';

export const mapPageToSchema = ({ seo }: ICreateSchemaParams): SchemaJsonLD.WebPage => ({
  '@context': 'http://schema.org',
  '@type': 'WebPage',
  name: seo?.title,
  breadcrumb: {
    '@type': 'BreadcrumbList',
    numberOfItems: seo?.breadcrumbs?.length,
    itemListElement: seo?.breadcrumbs?.reduce(
      (acc, { title, url }, index) => [
        ...acc,
        {
          '@type': 'ListItem',
          position: index + 1,
          name: title,
          item: `${BASE_URL}${url}`,
        },
      ],
      [],
    ) ?? [{ '@type': 'ListItem', position: 1, name: '💰Микрозаймы', item: `${BASE_URL}/zaimy/` }],
  },
  author: {
    '@type': 'Organization',
    name: 'Сравни.ру',
    url: `${BASE_URL}/`,
    logo: 'https://www.sravni.ru/f/_/newdesign/img/logo2.svg',
  },
});
