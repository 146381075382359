import { DesktopHeader, Header, MobileHeader } from '@sravni/react-header';

export const getHeader = (variant?: 'mobile' | 'desktop') => {
  if (!variant) {
    return Header;
  }

  if (variant === 'mobile') {
    return MobileHeader;
  }

  return DesktopHeader;
};
