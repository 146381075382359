import React from 'react';

import { BANNER_TYPES, Banner } from '@sravni/react-advertisement';

import { BANNER_TYPE_TOP } from '@src/constants/banners';
import { useSelectBanners } from '@src/reducers/banners';

export const TopBanner = () => {
  const banners = useSelectBanners();

  return <Banner abName="" banner={banners?.[BANNER_TYPE_TOP]} type={BANNER_TYPES.TOP} />;
};
