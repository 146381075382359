import type { ThemeNames } from '@sravni/design-system-theme';
import { parseWLUrl } from '@sravni/utils/lib/analytics';

import { GTM_ID } from '@src/constants/analytics';

interface IParams {
  userId?: string | number;
  isWhiteLabel: boolean;
  asPath: string;
  theme?: ThemeNames;
}

export function getGtmOptions(params: IParams) {
  const { userId, isWhiteLabel, asPath, theme } = params;

  const fullUrl = `https://www.sravni.ru${asPath}`;
  const whiteLabelOptions = isWhiteLabel ? parseWLUrl(fullUrl) : undefined;

  const common = {
    enabled: true,
    userId,
    theme,
  };

  if (isWhiteLabel) {
    return {
      ...common,
      googleTmId: GTM_ID.WL,
      wl: whiteLabelOptions,
    };
  }

  return {
    ...common,
    googleTmId: GTM_ID.DEFAULT,
  };
}
