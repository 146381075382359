import cn from 'classnames';
import type { ReactNode } from 'react';
import React, { memo } from 'react';

import { Space, Typography } from '@sravni/react-design-system';

import styles from './Banner.module.scss';

interface Props {
  title: ReactNode;
  titleClassName?: string;

  subtitle: ReactNode;
  subtitleClassName?: string;

  rightSlot?: ReactNode;

  containerClassName?: string;
  contentClassName?: string;

  onClick?(): void;
}

export const Banner = memo((props: Props) => {
  const {
    title,
    titleClassName,
    subtitle,
    subtitleClassName,
    containerClassName,
    contentClassName,
    rightSlot,
    onClick,
  } = props;

  return (
    <Space className={cn(styles.container, containerClassName)} onClick={onClick}>
      <Space className={contentClassName} direction="vertical" size={8}>
        <Typography.Heading level={5} className={titleClassName}>
          {title}
        </Typography.Heading>
        <Typography.Text size={10} className={subtitleClassName}>
          {subtitle}
        </Typography.Text>
      </Space>

      {rightSlot}
    </Space>
  );
});

Banner.displayName = 'Banner';
