import getConfig from 'next/config';
import { useRouter } from 'next/router';
import React, { memo, useMemo } from 'react';

import { GlobalSearch } from '@sravni/react-search/lib/GlobalSearch';

import { getFormatPath } from '@src/helpers/getFormatPath';
import { useSelectLocations } from '@src/reducers/locations';
import { useSelectHeaderSettings } from '@src/reducers/siteSettings';
import { useSelectUser } from '@src/reducers/user';

import { getHeader } from '../lib';

interface Props {
  mode: 'landing' | 'default';

  variant?: 'mobile' | 'desktop';
}

export const Header = memo(({ mode, variant }: Props) => {
  const { asPath } = useRouter();

  const headerSettings = useSelectHeaderSettings();
  const { centers, currentLocation } = useSelectLocations();
  const user = useSelectUser();

  const formatPath = useMemo(() => getFormatPath(asPath, currentLocation.alias), [currentLocation.alias, asPath]);
  const Header = useMemo(() => getHeader(variant), [variant]);

  return (
    <Header
      serviceURL={asPath}
      regions={centers}
      currentRegion={currentLocation}
      settings={headerSettings}
      user={user}
      formatPath={formatPath}
      mode={mode}
      enableTheme
      search={<GlobalSearch searchServiceHost={getConfig().publicRuntimeConfig.publicUrl} asPath={asPath} />}
    />
  );
});

Header.displayName = 'Header';
