// eslint-disable-next-line no-shadow
export enum TERMS {
  minutes = 'minutes',
  hours = 'hours',
  days = 'days',
  months = 'months',
  years = 'years',
  seconds = 'seconds',
}

export interface ITermRange {
  from: string;
  fromUnit: TERMS;
  fromValue?: number;
  to: string;
  toUnit: TERMS;
  toValue?: number;
}

export interface IFromToRange {
  from: number;
  to: number;
}

export interface IFromToTerms {
  from: string | null;
  fromUnit: TERMS;
  fromValue: number | null;
  to: string | null;
  toUnit: TERMS;
  toValue: number | null;
}

export interface IFromToNumber {
  from: number | null;
  to: number | null;
}
export interface IFromToDate {
  from: Date | null;
  to: Date | null;
}
