import { BFF_PROXY_API } from '@src/shared/constants/apiRoutes';

import client from '../utils/client';

export const loadIndexingRegions = async (url: string) => {
  const { data } = await client.get(BFF_PROXY_API.getIndexedLocationsRoute, {
    params: { url },
  });

  return data;
};
