import type { Card } from '@src/@types/microcredits';

import { getRange } from './schema/getRange';
import { getRateMinimal } from './schema/getRateMinimal';

export const getTitle = (item: Card.IRes) => {
  const amountRange = getRange(item.rates.RUB, ['amount.from', 'amount.to']);

  return `Микрокредит ${item.name} от МФО ${item.organization.name} со ставкой ${getRateMinimal(item)}% на сумму от ${
    amountRange.minValue
  } до ${amountRange.maxValue} рублей`;
};
