import { getCurrency } from '@src/helpers/currency';
import type { ICreateSchemaParams, SchemaJsonLD } from 'entities/Seo/types';

import { BASE_URL } from '../../config';

import { getAddress } from './getAdress';
import { getRange } from './getRange';

export const mapCardToSchema = ({ microcredit, seo, path }: ICreateSchemaParams): SchemaJsonLD.ListItem => {
  const { rates, periods, amount } = microcredit;

  const rangeData = {
    amount: { minValue: amount.from, maxValue: amount.to },
    rate: { minValue: Infinity, maxValue: -Infinity },
    term: { minValue: periods.from, maxValue: periods.to },
  };

  rangeData.amount = getRange(rates.RUB, ['amount.from', 'amount.to']);

  rates.RUB.map((r) => r.periods)
    .flat()
    .forEach(({ rate }) => {
      rangeData.rate.minValue = Math.min(rate.from, rangeData.rate.minValue);
      rangeData.rate.maxValue = Math.max(rate.to, rangeData.rate.maxValue);
    });

  return {
    '@type': 'ListItem',
    position: 1,
    item: {
      '@type': 'LoanOrCredit',
      name: microcredit.name,
      description: `${seo?.productName} от МФО «${microcredit.organization.name}» с суммой от ${rangeData.amount.minValue} до ${rangeData.amount.maxValue} рублей на срок от ${rangeData.term.minValue} до ${rangeData.term.maxValue} дней.`,
      url: `${BASE_URL}/zaimy/mfo/${microcredit.organization.alias}/`,
      image: microcredit.organization.logotypes.android || microcredit.organization.logo,
      category: 'Займ',
      feesAndCommissionsSpecification: `${BASE_URL}/zaimy/mfo/${microcredit.organization.alias}/`,
      amount: {
        '@type': 'MonetaryAmount',
        currency: getCurrency(path),
        ...rangeData.amount,
      },
      loanTerm: {
        '@type': 'QuantitativeValue',
        name: 'Срок займа',
        ...rangeData.term,
        unitCode: 'DAY',
      },
      annualPercentageRate: {
        '@type': 'QuantitativeValue',
        name: 'Процентная ставка в день',
        ...rangeData.rate,
        unitText: '%',
      },
      broker: {
        '@type': 'BankOrCreditUnion',
        name: microcredit.organization.name,
        priceRange: '$$$$',
        image: [microcredit?.organization?.logotypes?.android || microcredit?.organization?.logo],
        url: `https://www.sravni.ru/mfo/${microcredit.organization.alias}/`,
        logo: microcredit?.organization?.logotypes?.android || microcredit?.organization?.logo,
        telephone: microcredit?.organization?.contacts?.phone,
        address: {
          '@type': 'PostalAddress',
          ...getAddress(microcredit?.organization?.contacts?.address, 'ru'),
        },
      },
    },
  };
};
