import pluralize from '@sravni/utils/lib/pluralize';

import { TERMS } from '@src/@types/ranges';

const UNITS_PLURALIZE_DICTIONARY: Record<TERMS, string[]> = {
  [TERMS.months]: ['месяц', 'месяца', 'месяцев'],
  [TERMS.years]: ['год', 'года', 'лет'],
  [TERMS.days]: ['день', 'дня', 'дней'],
  [TERMS.hours]: ['час', 'часа', 'часов'],
  [TERMS.minutes]: ['минута', 'минуты', 'минут'],
  [TERMS.seconds]: ['секунда', 'секунды', 'секунд'],
};

const UNITS_PLURALIZE_DICTIONARY_RANGE: Record<TERMS, string[]> = {
  [TERMS.months]: ['месяца', 'месяцев', 'месяцев'],
  [TERMS.years]: ['года', 'лет', 'лет'],
  [TERMS.days]: ['дня', 'дней', 'дней'],
  [TERMS.hours]: ['часа', 'часов', 'часов'],
  [TERMS.minutes]: ['минуты', 'минут', 'минут'],
  [TERMS.seconds]: ['секунда', 'секунды', 'секунд'],
};

interface IProps {
  value: number;
  initialForm?: boolean;
  unit: TERMS;
  source?: [string, string, string];
  isRange?: boolean;
}

export const plur = (props: IProps): string => {
  const { value, initialForm = true, unit, isRange = false } = props;

  const sourceRange = isRange ? UNITS_PLURALIZE_DICTIONARY_RANGE[unit] : UNITS_PLURALIZE_DICTIONARY[unit];
  if (!sourceRange) {
    return '';
  }

  const [a, b, c] = sourceRange;

  return initialForm ? pluralize(value, a, b, c) : pluralize(value, b, c, c);
};
