import type { IFAQMeta } from '@sravni/types/lib/seo';

import { escapeHTMLTags } from '@src/helpers/seo';
import type { ICreateSchemaParams, SchemaJsonLD } from 'entities/Seo/types';

export const mapFAQtoSchema = ({ seo }: ICreateSchemaParams): SchemaJsonLD.FAQPage => {
  const faq = seo?.contentBlocks?.find((value): value is IFAQMeta => value.type === 'faq');

  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity:
      faq?.serviceResponseData.items?.reduce(
        (acc, { question, answer }) => [
          ...acc,
          {
            '@type': 'Question',
            name: question,
            acceptedAnswer: {
              '@type': 'Answer',
              text: escapeHTMLTags(answer),
            },
          },
        ],
        [],
      ) || [],
  };
};
