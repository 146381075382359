import type { Card } from '@src/@types/microcredits';

import { getRateMinimal } from './schema/getRateMinimal';

export const getDescription = (item: Card.IRes) =>
  `Оформить микрокредит ${item.name} от МФО ${item.organization.name} на срок от ${item.periods.from} до ${
    item.periods.to
  } дней. Мгновенно получить от ${item.amount.from} до ${item.amount.to} рублей под ${getRateMinimal(
    item,
  )}% в день с переводом на карту или электронный кошелек.`;
