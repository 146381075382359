import { makeMainGEvent, pushEvent } from '@src/utils/analytics';
import { FULL_DEAL_EVENT_CATEGORY } from 'entities/FullDeal/config';

export const sendClickOnSubmitLoanEvent = (buttonName: string) => {
  pushEvent(
    makeMainGEvent({
      eventCategory: FULL_DEAL_EVENT_CATEGORY,
      eventAction: 'mfoNextButton',
      eventLabel: `state - ${buttonName}|screen - mfoFullDealLanding`,
    }),
  );
};

export const sendScrollFullDealStepsEvent = () => {
  pushEvent(
    makeMainGEvent({
      eventCategory: FULL_DEAL_EVENT_CATEGORY,
      eventAction: 'mfoNextButton',
      eventLabel: 'state - Свайп|screen - mfoFullDealLanding',
    }),
  );
};
