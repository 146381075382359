import type { Card } from '@src/@types/microcredits';

export const getRateMinimal = (item: Card.IRes): number => {
  let minimal = Infinity;
  if (!item.rates.RUB) {
    return 0;
  }
  for (const rate of item.rates.RUB) {
    for (const p of rate.periods) {
      minimal = Math.min(minimal, p.rate.from || Infinity);
    }
  }
  return minimal === Infinity ? 0 : minimal;
};
