import isEmpty from 'lodash/isEmpty';

import type { Seo } from '@sravni/types/lib/seo';
import { format } from '@sravni/utils/lib/money';
import pluralize from '@sravni/utils/lib/pluralize';

import type * as Microcredits from '@src/@types/microcredits';
import { getAmountString, humanizeTerm } from '@src/helpers/common';
import type { ILocation } from '@src/reducers/locations';

const pluralizeOffers = (n: number) => pluralize(n, 'предложение', 'предложения', 'предложений');
const pluralizeLoans = (n: number) => pluralize(n, 'займ', 'займа', 'займов');

function getTodayValue() {
  const date = new Date();
  return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}.${date.getFullYear()}`;
}

export const createReplacements = (meta?: Microcredits.List.IMetadata, location?: ILocation) => {
  const offersCount = meta?.counts.offers || 0;
  const organizationCount = meta?.counts.organizations || 0;
  const minRate = meta?.minRate?.rate || 0;
  const maxRate = meta?.maxRate?.rate || 0;
  const minAmount = meta?.minAmount?.amount || 0;
  const maxAmount = meta?.maxAmount?.amount || 0;

  return {
    /* eslint-disable @typescript-eslint/naming-convention */
    'город.где': location?.name || '',
    'город.родительный': location?.nameGenitive || '',
    'количество.предложений': `${offersCount} ${pluralizeOffers(offersCount)}`,
    'количество.организаций.где': `в ${organizationCount} МФО`,
    'количество.организаций': `${organizationCount} МФО`,
    'максимальный.срок': !meta ? '' : humanizeTerm(meta.maxTerm?.term, meta.minTerm?.termUnit),
    'минимальный.срок': !meta ? '' : humanizeTerm(meta.maxTerm?.term, meta.minTerm?.termUnit),
    'максимальная.сумма': !meta ? '' : getAmountString(maxAmount),
    'минимальная.сумма': !meta ? '' : getAmountString(minAmount),
    'максимальная.ставка': !meta ? '' : `${maxRate}`,
    'минимальная.ставка': !meta ? '' : `${minRate}`,
    год: String(new Date().getFullYear()),
    дата: !meta ? '' : getTodayValue(),
    /* eslint-enable @typescript-eslint/naming-convention */
  };
};

export const replaceMetadata = (metadata: Seo, replacements: Record<string, string>): Seo => {
  if (isEmpty(metadata)) {
    return metadata;
  }

  let string = JSON.stringify(metadata);
  Object.keys(replacements).forEach((key) => {
    const search = key.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1');
    string = string.replace(new RegExp(`{${search}}`, 'g'), replacements[key]);
  });

  return JSON.parse(string);
};

export const getCommonReplacements = (params: {
  template: string;
  meta?: Microcredits.List.IMetadata;
  location?: ILocation;
}): string => {
  const { template, meta, location } = params;
  const date = new Date();

  const offersCount = meta?.counts.offers || 0;
  const organizationCount = meta?.counts.organizations || 0;
  const minRate = meta?.minRate?.rate || 0;
  const maxRate = meta?.maxRate?.rate || 0;
  const minAmount = meta?.minAmount?.amount || 0;
  const maxAmount = meta?.maxAmount?.amount || 0;
  let result = template
    .replace(
      '{дата}',
      `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}.${date.getFullYear()}`,
    )
    .replace('{год}', date.getFullYear().toString());

  if (location) {
    result = result
      .replace('{город.где}', location.name || '')
      .replace('{город.родительный}', location.nameGenitive || '');
  }

  if (meta) {
    result = result
      .replace('{количество.предложений}', `${offersCount} ${pluralizeOffers(offersCount)}`)
      .replace('{количество.организаций.где}', `в ${organizationCount} МФО`)
      .replace('{количество.организаций}', `${organizationCount} МФО`)
      .replace('{максимальный.срок}', humanizeTerm(meta.maxTerm?.term, meta.minTerm?.termUnit))
      .replace('{минимальный.срок}', humanizeTerm(meta.maxTerm?.term, meta.minTerm?.termUnit))
      .replace('{максимальная.сумма}', getAmountString(maxAmount))
      .replace('{минимальная.сумма}', getAmountString(minAmount))
      .replace('{максимальная.ставка}', `${maxRate}`)
      .replace('{минимальная.ставка}', `${minRate}`);
  }

  return result;
};

export const getHeadingReplacements = (template: string, seo?: Partial<Seo>) => {
  const date = new Date();
  const seoAmount = seo?.meta?.amount || 0;
  const seoTerm = seo?.meta?.term || 0;

  return template
    .replace(
      '{дата}',
      `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}.${date.getFullYear()}`,
    )
    .replace('{год}', date.getFullYear().toString())
    .replace('{сумма}', `${format(seoAmount)} рублей`)
    .replace('{срок}', `${seoTerm} ${pluralize(seoTerm, 'год', 'года', 'лет')}`);
};

export const getSubheadingReplacements = (params: {
  template: string;
  meta?: Microcredits.List.IMetadata;
  location?: ILocation;
}): string => {
  const { template, meta, location } = params;
  const date = new Date();
  let result = template
    .replace(
      '{дата}',
      `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}.${date.getFullYear()}`,
    )
    .replace('{год}', date.getFullYear().toString());

  if (location) {
    result = result.replace('{город.где}', location.name).replace('{город.родительный}', location.nameGenitive || '');
  }

  if (meta) {
    result = result
      .replace('{количество.предложений}', `${meta.counts.offers} ${pluralizeLoans(meta.counts.offers)}`)
      .replace('{количество.организаций.где}', `в ${meta.counts.organizations} МФО`)
      .replace('{количество.организаций}', `${meta.counts.organizations} МФО`)
      .replace('{максимальный.срок}', humanizeTerm(meta.maxTerm.term, meta.minTerm.termUnit))
      .replace('{минимальный.срок}', humanizeTerm(meta.maxTerm.term, meta.minTerm.termUnit))
      .replace('{максимальная.сумма}', getAmountString(meta.maxAmount.amount))
      .replace('{минимальная.сумма}', getAmountString(meta.maxAmount.amount))
      .replace('{максимальная.ставка}', `${meta.maxRate?.rate || 0}`)
      .replace('{минимальная.ставка}', `${meta.minRate?.rate || 0}`);
  }

  return result;
};
